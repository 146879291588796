<template>
    <div class="page-title-view">
        <div class="internal-view">
            <n-button size="tiny" color="#d1d1d1" @click="historyBack" circle>
                <template #icon>
                    <ArrowBackFilled />
                </template>
            </n-button>
            <div class="title-text">
                {{ props.name || 'Page Title' }}
            </div>
        </div>
        <div class="suffix-view">
            <slot name="suffix"></slot>
        </div>
    </div>
</template>

<style lang="less" scoped>
.page-title-view {
    position: relative;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    .internal-view {
        display: flex;
        flex-direction: row;
        align-items: center;

        .title-text {
            font-size: 20px;
            font-weight: bold;
            line-height: 20px;
            padding-top: 2px;
            margin-left: 10px;
        }
    }

    .suffix-view {
        position: relative;
    }
}
</style>

<script setup>
import { useRouter } from 'vue-router';
import { NButton } from 'naive-ui';
import { ArrowBackFilled } from '@vicons/material';

const router = useRouter();

const props = defineProps({
    name: {
        type: String,
    },
    backAction: {
        type: Function,
    },
});

function historyBack() {
    props.backAction ? props.backAction() : router.go(-1);
}
</script>
