<template>
    <div class="model-data-view">
        <page-title :name="title" :back-action="goBack">
            <template #suffix>
                <n-button
                    size="small"
                    @click="editModel"
                    style="position: absolute; right: 0; top: -3px"
                    secondary
                    >编辑模型</n-button
                >
            </template>
        </page-title>
        <ctrl-bar
            v-if="!!modelInfo"
            :model-info="modelInfo"
            @ready="onStateReady"
        ></ctrl-bar>
        <div class="content">
            <template v-if="layoutData.length > 0">
                <div v-for="section in layoutData" class="section-item">
                    <div class="title">
                        <div class="label">{{ section.label }}</div>
                    </div>
                    <div class="rows">
                        <div v-for="row in section.rows" class="row">
                            <div v-for="column in row.columns" class="column">
                                <Panel
                                    v-if="getPanelById(column.data)"
                                    :model-id="route.params.id"
                                    :info="getPanelById(column.data)"
                                    :date-range="queryDateRange"
                                    :granularity="granularity"
                                    :filterKVs="filterKVs"
                                />
                                <div v-else class="empty">无内容</div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template v-else>
                <n-spin :show="loading" size="large">
                    <div v-if="panelList.length < 1" class="empty-content">
                        <div v-show="!loading" class="message">未配置图表</div>
                    </div>
                </n-spin>
                <div v-for="item in panelList" class="item">
                    <Panel
                        :model-id="route.params.id"
                        :info="item"
                        :date-range="queryDateRange"
                        :granularity="granularity"
                        :filterKVs="filterKVs"
                    />
                </div>
            </template>
        </div>
    </div>
</template>

<style lang="less" scoped>
@import '../../common/common.less';

@margin-size: 12px;
.model-data-view {
    .common-content;

    .ctrl-bar {
        .common-ctrl-bar;
        position: sticky;
        top: 0px;
        z-index: 2;
    }

    .content {
        margin-top: 10px;
        padding-bottom: 50px;
        min-height: 74vh;
        .item {
            height: 300px;
            .standard-border;
            .standard-shadow;
            margin-bottom: 10px;
        }

        .empty-content {
            height: 70vh;

            .message {
                text-align: center;
                padding-top: 20vh;
                font-size: 26px;
                color: #999;
            }
        }

        .section-item {
            .title {
                display: flex;
                flex-direction: row;
                align-items: center;

                .icon {
                    margin-right: 4px;
                }

                .label {
                    font-size: 20px;
                    font-weight: bold;
                }
            }

            .rows {
                margin-top: 6px;
                .row {
                    margin-bottom: @margin-size;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-around;

                    .column {
                        margin-right: @margin-size;
                        height: 330px;
                        width: 30%;
                        flex-grow: 1;
                        .standard-border;
                        .standard-shadow;

                        &:last-child {
                            margin-right: 0;
                        }

                        .empty {
                            background-color: #fff;
                            height: inherit;
                            text-align: center;
                            font-size: 16px;
                            color: #999;
                            line-height: 320px;
                        }
                    }
                }
            }
        }
    }
}
</style>

<script setup>
import { ref, computed, nextTick } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { NSpin, NButton } from 'naive-ui';

import PageTitle from '@/components/PageTitle';
import CtrlBar from './components/ControlBar';
import Panel from './components/AnalysisPanel';

import { CostNervAPI } from '@/common/API';

const route = useRoute();

const router = useRouter();

let title = ref('loading...');

let loading = ref(false);

let modelInfo = ref(null);

let queryDateRange = computed(() => [
    +route.query.start / 1000,
    +route.query.end / 1000,
]);

let granularity = computed(() => route.query.granularity);

let filterKVs = computed(() =>
    route.query.filters ? JSON.parse(route.query.filters) : null
);

function onStateReady() {
    loadPanels();
}

let panelList = ref([]);

function loadPanels() {
    CostNervAPI.getPanelList(route.params.id).then(res => {
        loading.value = false;
        if (res.error_no !== 0) {
            message.error(res.error_msg || '未知异常导致加载失败');
            return;
        }
        panelList.value = [];
        nextTick(() => (panelList.value = res.data.list));
    });
}

function getPanelById(pId) {
    return panelList.value.find(item => item.id === pId);
}

let layoutData = ref([]);

function loadData() {
    loading.value = true;
    CostNervAPI.getModel(route.params.id).then(res => {
        if (res.error_no !== 0) {
            message.error(res.error_msg || '未知异常导致加载失败');
            return;
        }
        title.value = res.data.name;
        modelInfo.value = res.data;
        layoutData.value = res.data.layoutV1 || [];
    });
}

loadData();

function editModel() {
    router.push(`/cost_nerv/model/${route.params.id}/editor`);
}

function goBack() {
    router.replace(`/cost_nerv/model/_list`);
}
</script>
