/**
 * @file Model Analysis Tools
 */

import dayjs from 'dayjs';
import * as echarts from 'echarts/core';
import {
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    GridComponent,
} from 'echarts/components';
import { LineChart, BarChart, PieChart } from 'echarts/charts';
import { LabelLayout, UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import { AdvancedTooltip, yAxisLabelFormatter } from '@/common/EChartsTools';
import { formatNumber, isEmptyObject } from '@/common/tools';

export const defaultDateRangeMap = {
    today: {
        label: '当天',
        range(offset = 0) {
            return [
                dayjs().add(offset, 'day').startOf('day').unix(),
                dayjs().add(offset, 'day').startOf('hour').unix(),
            ];
        },
    },
    last3: {
        label: '最近3天',
        range(offset = 0) {
            return [
                dayjs()
                    .add(offset, 'day')
                    .subtract(2, 'day')
                    .startOf('day')
                    .unix(),
                dayjs().add(offset, 'day').startOf('hour').unix(),
            ];
        },
    },
    last7: {
        label: '最近7天',
        range(offset = 0) {
            return [
                dayjs()
                    .add(offset, 'day')
                    .subtract(6, 'day')
                    .startOf('day')
                    .unix(),
                dayjs().add(offset, 'day').startOf('hour').unix(),
            ];
        },
    },
};

echarts.use([
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    CanvasRenderer,
    LabelLayout,
    GridComponent,
    LineChart,
    BarChart,
    PieChart,
    UniversalTransition,
]);

const FORMAT = 'YYYY-MM-DD HH:mm:ss';

function formatLegendName(data, format) {
    let copyFormatter = format;
    if (!copyFormatter || copyFormatter === '') {
        return data;
    }
    let dataObj = null;
    try {
        dataObj = JSON.parse(data);
    } catch (e) {
        return data;
    }
    for (let key in dataObj) {
        copyFormatter = copyFormatter.replaceAll(`{${key}}`, dataObj[key]);
    }
    return copyFormatter;
}

export class AnalysisChart {
    echartInstance = null;

    aTooltip = null;

    data = null;

    chartStyle = 'line';

    legendStyle = 'right';

    legendNameFormat = null;

    constructor(dom) {
        this.echartInstance = echarts.init(dom);
        this.aTooltip = new AdvancedTooltip(dom);
    }

    resize(obj) {
        this.echartInstance.resize(obj);
    }

    dispose() {
        this.echartInstance.dispose();
    }

    clear() {
        this.echartInstance && this.echartInstance.clear();
    }

    setData(data) {
        this.data = data;
        // 将setData视为大刷新，需要clear之后再渲染，以免触发脏渲染带来的显示bug
        this.clear();
        return this;
    }
    // line,bar
    setStyle(style) {
        this.chartStyle = style || 'line';
        return this;
    }
    // none,right,bottom
    setLegendStyle(style) {
        this.legendStyle = style || 'right';
        return this;
    }

    setLegendNameFormat(format) {
        this.legendNameFormat = format;
        return this;
    }

    setStylesByPanelInfo(info) {
        this.chartStyle = info.style || 'line';
        this.legendStyle = info.legendStyle || 'right';
        this.legendNameFormat = info.legendNameFormatter;
        return this;
    }

    render() {
        let resData = this.data.values;
        if (isEmptyObject(resData)) {
            return;
        }
        let legends = Object.keys(resData);
        let axisData = Object.keys(resData[legends[0]]);

        let opt = {
            grid: {
                top: 20,
                left: 60,
                right: 40,
                bottom: 30,
            },
            tooltip: this.aTooltip.tooltip({
                valueFormatter: value => formatNumber(value),
                maxHeight: 200,
            }),
            xAxis: {
                type: 'category',
                data: axisData.map(item => dayjs(item * 1000).format(FORMAT)),
            },
            yAxis: {
                type: 'value',
                ...yAxisLabelFormatter(),
            },
            series: legends.map(key => ({
                name: formatLegendName(key, this.legendNameFormat),
                type: this.chartStyle,
                showSymbol: false,
                data: axisData.map(
                    date => resData[key][date] && +resData[key][date].toFixed(2)
                ),
                triggerLineEvent: true,
            })),
        };

        if (this.legendStyle === 'right') {
            opt.grid.right = 150;
            opt.legend = {
                show: true,
                type: 'scroll',
                data: legends.map(key =>
                    formatLegendName(key, this.legendNameFormat)
                ),
                orient: 'vertical',
                right: 0,
                top: 'middle',
                textStyle: {
                    width: 106,
                    overflow: 'breakAll',
                },
                selector: [
                    {
                        // 全选
                        type: 'all',
                        // 可以是任意你喜欢的标题
                        title: '全选',
                    },
                    {
                        // 反选
                        type: 'inverse',
                        // 可以是任意你喜欢的标题
                        title: '反选',
                    },
                ],
            };
        }
        if (this.legendStyle === 'bottom') {
            opt.grid.bottom = 50;
            opt.legend = {
                show: true,
                type: 'scroll',
                data: legends.map(key =>
                    formatLegendName(key, this.legendNameFormat)
                ),
                orient: 'horizontal',
                left: 'center',
                bottom: 0,
                selector: null,
                textStyle: {
                    width: 'auto',
                },
            };
        }

        if (this.legendStyle === 'none') {
            opt.legend = {
                show: false,
            };
        }

        this.echartInstance.setOption(opt);
    }
}
