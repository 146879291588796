<template>
    <ChartBox
        :name="props.info.name"
        :loading="loading"
        @mounted="renderChart"
        @resize="onViewResize"
    >
        <template #header>
            <n-popover
                v-if="filterItems.length > 0"
                trigger="click"
                :show-arrow="false"
                placement="bottom-end"
            >
                <template #trigger>
                    <n-button
                        size="tiny"
                        style="margin-top: 5px; margin-right: 2px"
                        quaternary
                    >
                        <template #icon>
                            <n-icon>
                                <FilterAltFilled />
                            </n-icon>
                        </template>
                    </n-button>
                </template>
                <div class="filter-view">
                    <template v-for="item in filterItems">
                        <div class="label">{{ item.key }}</div>
                        <div class="input-line">
                            <n-select
                                class="input"
                                size="tiny"
                                v-model:value="filterKVs[item.key]"
                                :options="getFilterOptions(item.options)"
                                :consistent-menu-width="false"
                                multiple
                                filterable
                                clearable
                            ></n-select>
                        </div>
                    </template>
                    <div class="submit">
                        <n-button
                            size="tiny"
                            type="primary"
                            @click="onFilterSubmit"
                            >确定</n-button
                        >
                    </div>
                </div>
            </n-popover>
            <template v-if="props.editMode">
                <n-button
                    size="tiny"
                    style="margin-top: 5px; margin-right: 2px"
                    @click="emits('edit', props.info)"
                    quaternary
                >
                    <template #icon>
                        <n-icon>
                            <EditFilled />
                        </n-icon>
                    </template>
                </n-button>
                <n-popconfirm
                    @positive-click="emits('delete', props.info)"
                    placement="bottom-end"
                >
                    <template #trigger>
                        <n-button
                            size="tiny"
                            style="margin-top: 5px"
                            quaternary
                        >
                            <template #icon>
                                <n-icon>
                                    <DeleteFilled />
                                </n-icon>
                            </template>
                        </n-button>
                    </template>
                    确定删除该Panel?
                </n-popconfirm>
            </template>
        </template>
    </ChartBox>
</template>

<style lang="less" scoped>
.filter-view {
    .label {
        font-size: 12px;
        font-weight: bold;
        margin-bottom: 2px;
    }

    .input-line {
        display: flex;
        flex-direction: row;

        .exclude {
            margin-left: 8px;
        }
    }

    .input {
        width: 300px;
        margin-bottom: 10px;
    }

    .submit {
        display: flex;
        flex-direction: row;
        justify-content: right;
    }
}
</style>

<script setup>
import { ref, watch, computed, nextTick, onUnmounted } from 'vue';

import {
    NIcon,
    NButton,
    NSelect,
    NPopconfirm,
    NPopover,
    useMessage,
} from 'naive-ui';
import { DeleteFilled, EditFilled, FilterAltFilled } from '@vicons/material';

import { isEmptyArray } from '@/common/tools';

import ChartBox from '@/components/ChartBox';
import { CostNervAPI } from '@/common/API';
import { AnalysisChart } from './tools';

const message = useMessage();

const props = defineProps({
    modelId: {
        type: String,
    },
    info: {
        type: Object,
        default: () => ({ name: '' }),
    },
    dateRange: {
        type: Array,
        default: () => [1692633600, 1693238400],
    },
    granularity: {
        type: String,
    },
    filterKVs: {
        type: Object,
        default: () => ({}),
    },
    editMode: {
        type: Boolean,
        default: false,
    },
});

const emits = defineEmits(['edit', 'delete']);

let aChartInstance = null;

function renderChart(dom) {
    aChartInstance = new AnalysisChart(dom);
}

function onViewResize({ width }) {
    aChartInstance.resize({ width });
}

let filterKVs = ref(
    props.filterKVs || { ...props.info.defaultFilterKVs } || {}
);

let filterKVQuerys = computed(() => {
    let querys = {};
    for (let key in filterKVs.value) {
        if (!isEmptyArray(filterKVs.value[key])) {
            querys[key] = filterKVs.value[key];
        }
    }
    return querys;
});

let filterItems = computed(() => {
    return Object.keys(props.info.filters || {}).map(key => ({
        key,
        options: props.info.filters[key],
    }));
});

function getFilterOptions(array) {
    return array.map(item => ({ label: item, value: item }));
}

function onFilterSubmit() {
    loadData();
}

let loading = ref(false);

function loadData() {
    aChartInstance && aChartInstance.clear();
    loading.value = true;
    CostNervAPI.calculateQuery(
        props.modelId,
        props.info.queryId,
        props.dateRange[0],
        props.dateRange[1],
        props.granularity,
        filterKVQuerys.value
    )
        .then(res => {
            loading.value = false;
            if (res.error_no !== 0) {
                message.error(res.error_msg || '未知异常导致加载失败');
                return;
            }
            aChartInstance
                .setStylesByPanelInfo(props.info)
                .setData(res.data)
                .render();
        })
        .catch(err => {
            console.log(err);
            loading.value = false;
        });
}

loadData();

watch(
    () => props.dateRange,
    () => {
        nextTick(() => {
            if (loading.value) {
                return;
            }
            loadData();
        });
    }
);
watch(
    () => props.granularity,
    () => {
        nextTick(() => {
            if (loading.value) {
                return;
            }
            loadData();
        });
    }
);
watch(
    () => props.filterKVs,
    value => {
        filterKVs.value = value || { ...props.info.defaultFilterKVs } || {};
        nextTick(() => {
            if (loading.value) {
                return;
            }
            loadData();
        });
    }
);

onUnmounted(() => {
    aChartInstance.dispose();
});
</script>
