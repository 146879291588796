<template>
    <div class="ctrl-bar">
        <div class="block">
            <div class="label">时间区间：</div>
            <n-date-picker
                v-model:value="dateRange"
                class="input"
                style="width: 340px"
                size="small"
                type="datetimerange"
                :is-date-disabled="isDateDisabled"
            />
        </div>
        <div class="block">
            <div class="label">粒度：</div>
            <n-select
                v-model:value="granularity"
                :options="granularityOpts"
                class="input"
                style="width: 100px"
                size="small"
                clearable
            ></n-select>
        </div>
        <div class="block" v-for="item in filterItems">
            <div class="label">{{ item.key }}：</div>
            <n-select
                v-model:value="filterKVs[item.key]"
                :options="
                    item.options.map(value => ({
                        label: value,
                        value,
                    }))
                "
                class="input"
                style="width: 200px"
                size="small"
                :consistent-menu-width="false"
                clearable
                multiple
                filterable
            ></n-select>
        </div>

        <n-button class="submit" size="small" type="primary" @click="onSearch">
            <template #icon>
                <n-icon>
                    <SearchFilled />
                </n-icon>
            </template>
            查询
        </n-button>
    </div>
</template>

<style lang="less" scoped>
@import '../../../common/common.less';

.ctrl-bar {
    .common-ctrl-bar;
}
</style>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { NDatePicker, NSelect, NButton, NIcon } from 'naive-ui';
import { SearchFilled } from '@vicons/material';

import dayjs from 'dayjs';

import { isEmptyObject, isEmptyArray } from '@/common/tools';
import { defaultDateRangeMap } from './tools';

const route = useRoute();

const router = useRouter();

const props = defineProps({
    modelInfo: {
        type: Object,
    },
});

const emits = defineEmits(['ready']);

function getModelDefault(key) {
    if (isEmptyObject(props.modelInfo.default)) {
        return null;
    }
    return props.modelInfo.default[key];
}

let dateRange = ref(
    defaultDateRangeMap[getModelDefault('dateRangeType') || 'last7']
        .range(getModelDefault('todayOffset') || 0)
        .map(ts => ts * 1000)
);

function isDateDisabled(date) {
    let now = dayjs().add(getModelDefault('todayOffset') || 0, 'day');
    return date > now.valueOf() || date < now.subtract(30, 'day').valueOf();
}

let granularityOpts = ref([
    { label: '自动', value: '' },
    { label: '1小时', value: '1h' },
    { label: '1天', value: '1d' },
]);

let granularity = ref('');

function getFilterJsonStr() {
    let querys = {};
    for (let key in filterKVs.value) {
        if (!isEmptyArray(filterKVs.value[key])) {
            querys[key] = filterKVs.value[key];
        }
    }
    return isEmptyObject(querys) ? null : JSON.stringify(querys);
}

let filterItems = computed(() => {
    let filters = props.modelInfo.filters || {};
    return Object.keys(filters).map(key => ({ key, options: filters[key] }));
});

let filterKVs = ref(getModelDefault('filterKVs') || {});

function setRouteParams() {
    let filters = getFilterJsonStr();
    router.replace({
        query: {
            start: dateRange.value[0],
            end: dateRange.value[1],
            ...(granularity.value === ''
                ? {}
                : { granularity: granularity.value }),
            ...(filters ? { filters } : {}),
            t: new Date().getTime(),
        },
    });
}

function getParamsFromRoute() {
    if (route.query.start && route.query.end) {
        dateRange.value = [+route.query.start, +route.query.end];
    }
    if (route.query.granularity) {
        granularity.value = route.query.granularity;
    }
    if (route.query.filters) {
        filterKVs.value = JSON.parse(route.query.filters);
    }
}

function onSearch() {
    setRouteParams();
}

onMounted(() => {
    getParamsFromRoute();
    setRouteParams();
    emits('ready');
});
</script>
